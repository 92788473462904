import React from 'react';
import { Event } from './Tracking';

import TwitterIcon from '../assets/images/top/twitter.png';
import FacebookIcon from '../assets/images/top/facebook.png';
import InstagramIcon from '../assets/images/top/instagram.png';
import LineIcon from '../assets/images/top/line.png';
class Footer extends React.Component {
    render() {
        return (
            <footer style={{display: this.props.display}}>
                <ul className="footer-sns">
                    <li><a href="https://twitter.com/complicitymovie" onClick={Event('sns', 'click', 'twitter')} target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="twitter" /></a></li>
                    <li><a href="https://www.facebook.com/Complicity.jp/" onClick={Event('sns', 'click', 'facebook')} target="_blank" rel="noopener noreferrer"><img src={FacebookIcon} alt="facebook" /></a></li>
                    <li><a href="https://www.instagram.com/complicity.movie/" onClick={Event('sns', 'click', 'instagram')} target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="insta" /></a></li>
                    <li><a href="http://nav.cx/7u2O4ah" onClick={Event('sns', 'click', 'line')} target="_blank" rel="noopener noreferrer"><img src={LineIcon} alt="line" /></a></li>
                </ul>
                <p className="copyright">©2018 CREATPS / Mystigri Pictures</p>
            </footer>
        );
    }
}

export default Footer;