import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Fnav02 from "../assets/images/top/f-nav02.png";
import Fnav03 from "../assets/images/top/f-nav03.png";

class FooterNav extends React.Component {
    render() {
        return (
            <Style>
                <ul className="footer-nav">
                    <li><Link to="#" onClick={(e) => {this.props.openModal(e,"MSTiiOk_PMU","本予告");}} data-id="MSTiiOk_PMU" className="movie-open modal-movie-btn"><img src={Fnav02} alt="" />本予告（90秒）</Link></li>
                    <li><a href="https://mvtk.jp/Film/065787" target="_blank" rel="noopener noreferrer" ><img src={Fnav03} alt="" /></a></li>
                </ul>
            </Style>
        );
    }
}
const Style = styled.div`
    .footer-nav {
        display: none;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    @media (max-width: 768px) {
        .footer-nav {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }
    }
    .footer-nav li {
        background-color: #000;
        border-left: 1px solid #fff;
        width: 50%;
        padding: 14px 0;
    }
    @media (max-width: 768px) {
        .footer-nav li {
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
            padding-bottom: env(safe-area-inset-bottom);
        }
    }
    .footer-nav li:first-of-type {
        border: none;
        background-color: #fef005;
        width: 50%;
        font-weight: 700;
    }
    .footer-nav li:first-of-type img {
        margin-right: 10px;
    }
    .footer-nav li a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 1.4rem;
        letter-spacing: 0.2em;
        padding: 0 0 14px;
        font-family: 游ゴシック体, YuGothic, 游ゴシック, Yu Gothic, メイリオ,
            sans-serif;
    }
    .footer-nav li a img {
        width: auto;
        height: 20px;
    }
    #billing {
        position: relative;
        font-weight: 600;
        padding: 60px 80px 65px;
        font-size: 11px;
        letter-spacing: 0.01em;
        background-color: #fff;
        line-height: 1.6;
    }
`;
export default FooterNav;
