import React, { Component, Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

import './assets/css/reset.css';
import './assets/css/common.css';

import Header from './components/Header';
import Modal from './components/Modal';
import FooterNav from './components/FooterNav';
import Footer from './components/Footer';

const Home = lazy(() => import('./pages/Home'));
const IntroStory = lazy(() => import('./pages/IntroStory'));
const Cast = lazy(() => import('./pages/Cast'));
const Staff = lazy(() => import('./pages/Staff'));
const Music = lazy(() => import('./pages/Music'));
const Media = lazy(() => import('./pages/Media'));
const Art = lazy(() => import('./pages/Art'));
const Hanzawa = lazy(() => import('./pages/Hanzawa'));
const Hirama = lazy(() => import('./pages/Hirama'));
const TheBook = lazy(() => import('./pages/TheBook'));
const Event = lazy(() => import('./pages/Event'));

class App extends Component {

  constructor(){
    super();
    this.state = {
      modal: 'none',
      id: null,
      displayHead: 'none',
      displayFoot: 'none'
    }
  }

  openModal = (e, id) => {
    e.stopPropagation();
    const modalButtons = document.getElementsByClassName('modal-movie-btn');
    for(const b of modalButtons){
      if(b.dataset.id !== e.target.dataset.id){
        b.classList.remove('active');
      }
    }
    e.target.classList.add('active');
    this.setState({
      modal: 'block',
      id: id
    });
  }

  closeModal = (e) => {
    this.setState({
      modal: 'none'
    });
  }

  displayChange = () => {
    this.setState({
      displayHead: 'flex',
      displayFoot: 'block'
    })
  }
  componentDidMount() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
  }

  render(){
    return (
      <div >
        <Header display={this.state.displayHead} openModal={this.openModal} />
        <Suspense fallback={<div></div>}>
          <Route exact path='/'>
            <Home displayChange={this.displayChange} />
            <FooterNav openModal={this.openModal} closeModal={this.closeModal} display={{display: this.state.modal}} id={this.state.id} />
          </Route>
          <Route path='/introstory'>
            <IntroStory displayChange={this.displayChange} />
          </Route>
          <Route path='/cast'>
            <Cast displayChange={this.displayChange} />
          </Route>
          <Route path='/staff'>
            <Staff displayChange={this.displayChange} />
          </Route>
          <Route path='/music'>
            <Music displayChange={this.displayChange} />
          </Route>
          <Route path='/media'>
            <Media displayChange={this.displayChange} />
          </Route>
          <Route path='/art'>
            <Art displayChange={this.displayChange} />
          </Route>
          <Route path='/photo/hanzawa/'>
            <Hanzawa displayChange={this.displayChange} />
          </Route>
          <Route path='/photo/hirama/'>
            <Hirama displayChange={this.displayChange} />
          </Route>
          <Route path='/book'>
            <TheBook openModal={this.openModal} displayChange={this.displayChange}　/>
          </Route>
          <Route path='/event'>
            <Event displayChange={this.displayChange}　/>
          </Route>
        </Suspense>
        <Modal openModal={this.openModal} closeModal={this.closeModal} display={{display: this.state.modal}} id={this.state.id} />
        <Footer display={this.state.displayFoot}　/>
      </div>
    );
  }
}

export default App;