import React from 'react';
import { Link } from 'react-router-dom';
import { Event } from './Tracking';

class Modal extends React.Component {
    switchMovie = (e, id, text) => {
        this.props.openModal(e, id);
        Event('header', 'click', text);
    }
    render(){
        let src;
        const id = this.props.id;
        if(id){
            src = `https://www.youtube.com/embed/${id}?enablejsapi=1?vq=highres`;
        }
        return (
            <div className="modal" style={this.props.display} onClick={this.props.closeModal.bind(this)}>
                <div className="modal-inner movie-wraper">
                    <div className="modal-close" onClick={this.props.closeModal}><span></span><span></span></div>
                    <div className="modal-iframe">
                        <iframe className="iframe" width="600" height="450" src={src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="youtube"></iframe>
                    </div>
                    <div className="modal-movie">
                        <Link to="#" onClick={ (e)=>{this.switchMovie(e, "MSTiiOk_PMU", "本予告");}} data-id="MSTiiOk_PMU" className="modal-movie-btn active"><span>本予告</span></Link>
                        <Link to="#" onClick={ (e)=>{this.switchMovie(e, "Rdg17vHxD-U", "30秒予告");}} data-id="Rdg17vHxD-U" className="modal-movie-btn"><span>30秒予告</span></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;