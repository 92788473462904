import React from 'react';
import { Link } from 'react-router-dom';
import SlideToggle from "react-slide-toggle";
import {HashLink} from 'react-router-hash-link';
import { Event } from './Tracking';

import HeaderLogoImg from "../assets/images/top/header-logo.png";
import TwitterLogoImg from "../assets/images/top/twitter.png";
import FaceBookLogoImg from "../assets/images/top/facebook.png";
import InstagramLogoImg from "../assets/images/top/instagram.png";
import LineLogoImg from "../assets/images/top/line.png";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSp: false,
            isOpen : false
        }
    }

    componentDidMount() {
        this.checkWindowWidth();
        window.addEventListener('resize', () => {
            this.checkWindowWidth();
        });
    }
    checkWindowWidth() {
        const w = window.innerWidth;
        const fw = 1024;
        if(w <= fw){
            this.setState({isSp: true});
        }else{
            this.setState({isSp: false});
            document.getElementsByTagName('body')[0].classList.remove('fixed');
        }
    }
    onClickHandler(){
        setTimeout(()=>{
            if(this.state.isSp){
                this.setState({isOpen: !this.state.isOpen});
            }
        }, 500);
    }
    modalFunc = (e, id) => {
        this.props.openModal(e, id);
        Event('header', 'click', '予告篇');
    }
    render() {
        if(this.state.isOpen === true){
            document.getElementsByTagName('body')[0].classList.add('fixed');
        }else{
            document.getElementsByTagName('body')[0].classList.remove('fixed');
        }
        return(
            <SlideToggle
            duration={500}
            collapsed={true}
            render={({ toggle, setCollapsibleElement})=>(
                <header className="header" style={{display: this.props.display}}>
                    <div className="header-btn" onClick={()=>{toggle();this.onClickHandler();}}><div className="header-btn__inner"><span></span><span></span></div></div>
                    <div className="header-inner" ref={this.state.isSp ? setCollapsibleElement : null} style={this.state.isSp && this.state.isOpen === false ? {display:"none"} : {display:"block"}}>
                        <div className="header-inner__img"><Link to="/"><img src={HeaderLogoImg} alt="" /></Link></div>
                        <ul className="header-inner__list">
                            <li><Link to="/" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', 'トップ')}}>トップ</Link></li>
                            <li><Link to="#" onClick={(e) => {this.modalFunc(e, "MSTiiOk_PMU")}} data-id="MSTiiOk_PMU" className="movie-open modal-movie-btn">予告篇</Link></li>
                            <li className="nav-hover">
                                <p>作品情報</p>
                                <ul className="second pr140">
                                    <li><HashLink to="/introstory/#intro" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', 'イントロダクション')}}>イントロダクション</HashLink></li>
                                    <li><HashLink to="/introstory/#story" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', 'ストーリー')}}>ストーリー</HashLink></li>
                                    <li><Link to="/cast/" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', 'キャスト')}}>キャスト</Link></li>
                                    <li><Link to="/staff/" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', 'スタッフ')}}>スタッフ</Link></li>
                                </ul>
                            </li>
                            <li className="nav-hover">
                                <p>スペシャル</p>
                                <ul className="second pr140">
                                    <li><Link to="/music/" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', '音楽');}}>音楽</Link></li>
                                    <li><Link to="/art/" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', 'アーティスト 伊吹拓')}}>アーティスト 伊吹拓</Link></li>
                                    <li><Link to="/photo/hanzawa/" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', '写真家 半沢克夫')}}>写真家 半沢克夫</Link></li>
                                    <li><Link to="/photo/hirama/" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', '写真家 平間至')}}>写真家 平間至</Link></li>
                                </ul>
                            </li>
                            <li><a href="https://complicity-movie.tumblr.com/" onClick={Event('header', 'click', 'ニュース')} target="_blank" rel="noopener noreferrer">ニュース</a></li>
                            <li><Link to="/media/" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', 'メディア情報');}}>メディア情報</Link></li>
                            <li><Link to="/book/" onClick={()=>{toggle();this.onClickHandler();Event('header', 'click', 'THE BOOK');}}>THE BOOK</Link></li>
                        </ul>
                        <ul className="header-inner__sns">
                            <li><a href="https://twitter.com/complicitymovie" onClick={Event('sns', 'click', 'twitter')} target="_blank" rel="noopener noreferrer"><img src={TwitterLogoImg} alt="twitter" /></a></li>
                            <li><a href="https://www.facebook.com/Complicity.jp/" onClick={Event('sns', 'click', 'facebook')} target="_blank" rel="noopener noreferrer"><img src={FaceBookLogoImg} alt="facebook" /></a></li>
                            <li><a href="https://www.instagram.com/complicity.movie/" onClick={Event('sns', 'click', 'instagram')} target="_blank" rel="noopener noreferrer"><img src={InstagramLogoImg} alt="insta" /></a></li>
                            <li><a href="http://nav.cx/7u2O4ah" onClick={Event('sns', 'click', 'line')} target="_blank" rel="noopener noreferrer"><img src={LineLogoImg} alt="line" /></a></li>
                        </ul>
                    </div>
                    <Link to="/event" />
                </header>
            )}
            />
        )
    }
}

export default Header;